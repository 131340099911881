@font-face {
  font-family: 'ALFontLBC';
  src: url('../assets/fonts/ALFont/ALFont-LBC-Regular.otf') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ALFontLBC';
  src: url('../assets/fonts/ALFont/ALFont-LBC-Bold.otf') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Thin.ttf') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-ExtraLight.ttf') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Light.ttf') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Regular.ttf') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Medium.ttf') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-SemiBold.ttf') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Bold.ttf') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Black.ttf') format('woff2');
  font-weight: 900;
  font-style: normal;
}
