span.language-toggle-thumb::after {
  content: 'EN';
  font-size: 0.6rem;
  font-weight: 600;
  position: absolute;
  top: 30%;
  left: 14%;
  z-index: 10000;
  pointer-events: none;
  color: black;
}

span.language-toggle-thumb::before {
  content: 'AR';
  font-size: 0.6rem;
  font-weight: 600;
  position: absolute;
  top: 30%;
  right: 14%;
  z-index: 10000;
  pointer-events: none;
  color: black;
}
