@import url('./fonts.css');

body {
  background-color: white !important;
}

@media only screen and (max-width: 1400px) {
  :root {
    font-size: 0.8em !important;
  }
}

@media only screen and (max-width: 960px) {
  :root {
    font-size: 0.75em !important;
  }
}

.MuiTablePagination-caption,
.MuiTablePagination-select,
.MuiTablePagination-selectIcon {
  display: none !important;
}
a {
  text-decoration: none !important;
}

.text-danger {
  color: red;
}
.MuiDropzoneSnackbar-successAlert {
  background-color: #01bfbf !important;
}

.SnackBarCustomFont {
  font-size: 0.9rem;
  font-weight: 200;
}

.MuiAccordion-root:before {
  height: 0 !important;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Hide number input spin buttons */
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

div.sc-gsDKAQ.imqUfa,
div#block-menu-container,
div#emoji-menu-container {
  z-index: 1400;
}
